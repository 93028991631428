import { get, post, createResponseFromRaw } from "../utils/ajax"

export const signUp = async (userDetails) => {
  const response = await post("/api/user", { body: userDetails })

  return createResponseFromRaw(response)
}

export const getActiveUser = async () => {
  const response = await get("/api/user/activeUser")

  return createResponseFromRaw(response)
}
