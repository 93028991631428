import React from "react"
import { Droppable } from "react-beautiful-dnd"
import LessonCard from "../LessonCard"

export default function CuricooLessons({
  lessons,
  isEditable,
  onEditLesson,
  onDeleteLesson
}) {
  return (
    <Droppable droppableId={"curicoo-lessons"}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {lessons.map((lesson, idx) => (
            <LessonCard
              onDeleteLesson={onDeleteLesson}
              onEditLesson={onEditLesson}
              isEditable={isEditable}
              lesson={lesson}
              index={idx}
              key={idx}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
