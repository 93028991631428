import React from "react"
import CuricooModal from "./CuricooModal"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { createCuricoo } from "../../../actions/curicoo.action"
import { useHistory } from "react-router-dom"
import { goToCuricoo } from "../../../utils/curicoo"

export default function CreateCuricooModalContainer({ open, handleClose }) {
  const [, dispatch] = useStateValue()
  const history = useHistory()

  const onCreateCuricoo = async curicoo => {
    const res = await createCuricoo(curicoo)

    if (res.error) {
      toastMessage(dispatch, "error", "Unable to create Curicoo")
    } else {
      dispatch({
        type: "curicoo.addCuricoo",
        curicoo: res.body
      })
      handleClose()
      goToCuricoo(history, dispatch, res.body.slugifiedKey)
    }
  }

  return (
    <CuricooModal
      open={open}
      mode={"CREATE"}
      handleClose={handleClose}
      onFormSubmit={onCreateCuricoo}
    />
  )
}
