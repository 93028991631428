import React from "react"
import SignIn from "./SignIn"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { login } from "../../../actions/auth.action"

export default function SignInContainer({ onSuccess, returnPath }) {
  const [, dispatch] = useStateValue()

  const onLogin = async (auth) => {
    const res = await login(auth)

    if (res.error) {
      toastMessage(dispatch, "error", "Incorrect email or password")
    } else {
      dispatch({
        type: "app.setUser",
        user: res.body
      })

      onSuccess()
    }
  }

  return <SignIn onLogin={onLogin} returnPath={returnPath} />
}
