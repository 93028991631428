import React from "react"
import get from "lodash/get"
import CuricooSearch from "./CuricooSearch"
import { searchCuricoos } from "../../../../actions/curicoo.action"
import { useStateValue } from "../../../../utils/state"
import { toastMessage } from "../../../../utils/toast"

export default function SearchContainer() {
  const [, dispatch] = useStateValue()

  const handleSearch = async term => {
    const result = await searchCuricoos(term)

    if (result.error || get(result.body, "length", 0) === 0) {
      toastMessage(dispatch, "error", "Unable to fetch Curicoos")
    } else {
      dispatch({
        type: "curicoo.setSearchResults",
        curicoos: result.body
      })
    }
  }

  const handleClear = () =>
    dispatch({
      type: "curicoo.setSearchResults",
      curicoos: []
    })

  return <CuricooSearch onSearch={handleSearch} onClear={handleClear} />
}
