const initialState = {
  curicoos: [],
  topCuricoos: [],
  searchResults: []
}

const getCuricoosWithoutCurrent = (curicoos, current) =>
  curicoos.filter(curicoo => curicoo.id !== current.id)

const stateWithUpdatedCurrent = (state, updatedCurrent) => {
  const curicoosWithoutCurrent = getCuricoosWithoutCurrent(
    state.curicoos,
    state.current
  )

  return {
    ...state,
    curicoos: [...curicoosWithoutCurrent, updatedCurrent],
    current: updatedCurrent
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "curicoo.addCuricoo": {
      return {
        ...state,
        curicoos: [...state.curicoos, action.curicoo],
        current: action.curicoo
      }
    }
    case "curicoo.updateCuricoo": {
      return stateWithUpdatedCurrent(state, action.curicoo)
    }
    case "curicoo.updateCuricooDetails": {
      const updatedCurrent = Object.assign({}, state.current, action.curicoo)

      return stateWithUpdatedCurrent(state, updatedCurrent)
    }
    case "curicoo.deleteCuricoo": {
      const curicoosWithoutCurrent = getCuricoosWithoutCurrent(
        state.curicoos,
        state.current
      )

      const topCuricoosWithoutCurrent = getCuricoosWithoutCurrent(
        state.topCuricoos,
        state.current
      )

      return {
        ...state,
        curicoos: curicoosWithoutCurrent,
        topCuricoos: topCuricoosWithoutCurrent,
        current: null
      }
    }
    case "curicoo.setCurrentCuricoo": {
      const newCurrent = [...state.curicoos, ...state.topCuricoos].find(
        curicoo => curicoo.slugifiedKey === action.slugifiedKey
      )

      return {
        ...state,
        current: newCurrent
      }
    }
    case "curicoo.unsetCurrentCuricoo": {
      return {
        ...state,
        current: null
      }
    }
    case "curicoo.addLessonToCurrent": {
      const updatedCurrent = Object.assign({}, state.current, {
        lessons: [...state.current.lessons, action.lesson]
      })

      return stateWithUpdatedCurrent(state, updatedCurrent)
    }
    case "curicoo.updateLessonOfCurrent": {
      const lessonsWithoutCurrent = state.current.lessons.filter(
        lesson => lesson.id !== action.lesson.id
      )

      const updatedCurrent = Object.assign({}, state.current, {
        lessons: [...lessonsWithoutCurrent, action.lesson]
      })

      return stateWithUpdatedCurrent(state, updatedCurrent)
    }
    case "curicoo.deleteLessonFromCurrent": {
      const lessonsWithoutCurrent = state.current.lessons.filter(
        lesson => lesson.id !== action.lessonId
      )

      const updatedLessonsIds = action.updatedLessons.map(lesson => lesson.id)
      const lessonsWithoutUpdated = lessonsWithoutCurrent.filter(
        lesson => !updatedLessonsIds.includes(lesson.id)
      )

      const updatedCurrent = Object.assign({}, state.current, {
        lessons: [...lessonsWithoutUpdated, ...action.updatedLessons]
      })

      return stateWithUpdatedCurrent(state, updatedCurrent)
    }
    case "curicoo.updateAllLessonsOfCurrent": {
      const updatedCurrent = Object.assign({}, state.current, {
        lessons: action.updatedLessons
      })

      return stateWithUpdatedCurrent(state, updatedCurrent)
    }
    case "curicoo.setTopCuricoos": {
      return {
        ...state,
        topCuricoos: action.curicoos
      }
    }
    case "curicoo.addTopCuricoos": {
      return {
        ...state,
        topCuricoos: [...state.topCuricoos, action.curicoos]
      }
    }
    case "curicoo.setSearchResults": {
      return {
        ...state,
        searchResults: action.curicoos
      }
    }
    default:
      return state
  }
}

export default { initialState, reducer }
