import React from "react"
import pick from "lodash/pick"
import LessonModal from "./LessonModal"
import { toastMessage } from "../../../utils/toast"
import { useStateValue } from "../../../utils/state"
import { updateLesson } from "../../../actions/lesson.action"

export default function EditLessonModalContainer({
  open,
  handleClose,
  curicooId,
  lessonData
}) {
  const [, dispatch] = useStateValue()

  const onUpdateLesson = async lesson => {
    const valuesToUpdate = pick(lesson, ["url", "title", "description", "type"])
    const res = await updateLesson(curicooId, lessonData.id, valuesToUpdate)

    if (res.body) {
      dispatch({
        type: "curicoo.updateLessonOfCurrent",
        lesson: res.body
      })
      toastMessage(dispatch, "success", "Lesson updated successfully")
      handleClose()
    } else {
      toastMessage(
        dispatch,
        "error",
        `Unable to update Lesson. ${res.error ? `Error: ${res.error}` : ""}`
      )
    }
  }

  return (
    <LessonModal
      open={open}
      mode={"UPDATE"}
      lesson={lessonData}
      handleClose={handleClose}
      handleFormSubmit={onUpdateLesson}
    />
  )
}
