export const post = (url, { body, headers }) =>
  fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...headers
    }
  })

export const get = url => fetch(url, { method: "GET" })

export const put = (url, { body, headers }) =>
  fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      ...headers
    }
  })

export const deleteHttp = url => fetch(url, { method: "DELETE" })

export const createResponseFromRaw = async (rawResponse, options = {}) => {
  const response = {
    statusCode: rawResponse.status
  }

  if (response.statusCode >= 200 && response.statusCode < 300) {
    response.body = options.useText
      ? await rawResponse.text()
      : await rawResponse.json()
  } else {
    response.error = rawResponse.error || rawResponse.statusText || true
  }

  return response
}
