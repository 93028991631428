import { makeStyles } from "@material-ui/core/styles"

const panelCssProps = {
  width: "100%",
  marginBottom: "16px"
}

export default makeStyles(theme => ({
  panel: {
    ...panelCssProps
  },
  draggedPanel: {
    ...panelCssProps,
    backgroundColor: "grey"
  },
  summary: {
    display: "flex",
    flexDirection: "column"
  },
  lessonNumber: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight
  },
  lessonTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium
  },
  lessonTypeIcon: {
    marginLeft: "10px"
  },
  lessonTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px"
  },
  lessonDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  lessonActions: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      margin: '15px 15px 0px 15px'
    }
  }
}))
