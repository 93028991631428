import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

export default function ConfirmationModal({
  open,
  title,
  message,
  onCancel,
  onOk,
  config
}) {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onCancel}
          color={config.cancelButtonColor || "primary"}
        >
          {config.cancelButtonText || "Cancel"}
        </Button>
        <Button onClick={onOk} color={config.okButtonColor || "primary"}>
          {config.okButtonText || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
