import React from "react"
import sortBy from "lodash/sortBy"
import CuricooLessons from "./CuricooLessons"

export default function CuricooLessonsContainer({
  lessons,
  isEditable,
  onEditLesson,
  onDeleteLesson
}) {
  const orderedLessons = sortBy(lessons, ["order"])

  return (
    <CuricooLessons
      lessons={orderedLessons}
      isEditable={isEditable}
      onEditLesson={onEditLesson}
      onDeleteLesson={onDeleteLesson}
    />
  )
}
