import React, { useEffect, useState } from "react"
import get from "lodash/get"
import pick from "lodash/pick"
import orderBy from "lodash/orderBy"
import cloneDeep from "lodash/cloneDeep"
import Curicoo from "./Curicoo"
import NotFound from "../NotFound"
import { useStateValue } from "../../utils/state"
import { toastMessage } from "../../utils/toast"
import { getCuricooBySlugifiedKey } from "../../actions/curicoo.action"
import { getLessons, updateLessons } from "../../actions/lesson.action"

const getCuricoo = async (curicooSlugifiedKey) => {
  const result = await getCuricooBySlugifiedKey(curicooSlugifiedKey)

  if (!result.error) {
    return result.body
  }
}

const getCuricooLessons = async (curicooId) => {
  const result = await getLessons(curicooId)

  if (!result.error) {
    return result.body
  } else {
    throw new Error(`Unable to fetch lessons for curicoo ${curicooId}`)
  }
}

export default function CuricooPageContainer({ match }) {
  const curicooSlugifiedKey = match.params.curicooSlugifiedKey

  const [isLoading, setIsLoading] = useState(true)

  const [
    {
      curicoo: { current },
      app: { user }
    },
    dispatch
  ] = useStateValue()

  useEffect(() => {
    const fetchCuricoo = async () => {
      if (isLoading) {
        let curicoo = current
        if (!curicoo) {
          curicoo = await getCuricoo(curicooSlugifiedKey)
          if (curicoo) dispatch({ type: "curicoo.addCuricoo", curicoo })
        }

        if (curicoo && !curicoo.lessons) {
          curicoo.lessons = await getCuricooLessons(curicoo.id)
          if (curicoo.lessons.length > 0)
            dispatch({ type: "curicoo.updateCuricoo", curicoo })
        }

        setIsLoading(false)

        return () => dispatch({ type: "curicoo.unsetCurrentCuricoo" })
      }
    }

    fetchCuricoo()
  }, [isLoading, current, curicooSlugifiedKey, dispatch])

  const reorderLessons = (lessons, srcIndex, destIndex) => {
    const result = Array.from(lessons)

    const [removed] = result.splice(srcIndex, 1)
    result.splice(destIndex, 0, removed)

    return result.map((lesson, idx) =>
      Object.assign({}, lesson, { order: idx + 1 })
    )
  }

  const updateLessonsInStore = async (updatedLessons, originalLessons) => {
    const updateArtifact = updatedLessons
      .filter((lesson, idx) => lesson.id === current.lessons[idx].id)
      .map((lesson) => pick(lesson, ["id", "order"]))

    dispatch({ type: "curicoo.updateAllLessonsOfCurrent", updatedLessons })

    const result = await updateLessons(current.id, updateArtifact)

    if (result.error) {
      toastMessage(dispatch, "error", "Unable to update lessons")
      dispatch({
        type: "curicoo.updateAllLessonsOfCurrent",
        updatedLessons: originalLessons
      })
    }
  }

  const handleLessonsReorder = ({ source, destination }) => {
    if (!destination || source.index === destination.index) return

    const originalLessons = cloneDeep(orderBy(current.lessons, ["order"]))

    const updatedLessons = reorderLessons(
      originalLessons,
      source.index,
      destination.index
    )
    updateLessonsInStore(updatedLessons, originalLessons)
  }

  const isEditable = get(user, "id") === get(current, "ownerId")
  const renderCuricooPage = isLoading || current

  return renderCuricooPage ? (
    <Curicoo
      curicoo={current}
      isEditable={isEditable}
      onLessonsReorder={handleLessonsReorder}
    />
  ) : (
    <NotFound />
  )
}
