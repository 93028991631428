import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none'
  }, 
  toolbar: {
    height: "76px",
    justifyContent: "space-between"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    cursor: 'pointer',
    '&:hover': {
      color: 'pink'
    }
  },
  logo: {
    fontFamily: '"Baloo Bhaina 2", cursive',
    fontSize: "1.9rem",
    paddingTop: "10px"
  },
  slogan: {
    position: "relative",
    top: "-10px"
  }
}))
