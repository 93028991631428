import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
  rootShareButton: {},
  shareMenuContainer: {
    display: "flex",
    alignItems: "center"
  },
  shareButtonsContainer: {
    marginLeft: "10px"
  },
  shareButton: {
    margin: "0 2px",
    '&:hover': {
      position: 'relative',
      top: '-5px'
    }
  }
}))
