import {
  post,
  get,
  put,
  deleteHttp,
  createResponseFromRaw
} from "../utils/ajax"

export const createLesson = async (curicooId, lesson) => {
  const response = await post(`/api/curicoo/${curicooId}/lesson`, {
    body: lesson
  })

  return createResponseFromRaw(response)
}

export const updateLesson = async (curicooId, lessonId, lesson) => {
  const response = await put(`/api/curicoo/${curicooId}/lessons/${lessonId}`, {
    body: lesson
  })

  return createResponseFromRaw(response)
}

export const deleteLesson = async (curicooId, lessonId) => {
  const response = await deleteHttp(
    `/api/curicoo/${curicooId}/lessons/${lessonId}`
  )

  return createResponseFromRaw(response)
}

export const getLessons = async curicooId => {
  const response = await get(`/api/curicoo/${curicooId}/lessons`)

  return createResponseFromRaw(response)
}

export const updateLessons = async (curicooId, lessons) => {
  const response = await put(`/api/curicoo/${curicooId}/lessons`, {
    body: lessons
  })

  return createResponseFromRaw(response)
}
