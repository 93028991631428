import React from "react"
import Dialog from "@material-ui/core/Dialog"
import isMobile from "is-mobile"

export default function ModalOrMobile({ children, onClose }) {
  return isMobile() ? (
    children
  ) : (
    <Dialog open={true} onClose={onClose}>
      {children}
    </Dialog>
  )
}
