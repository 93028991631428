import React, { useState } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
// import { ThemeProvider } from "@material-ui/core/styles"
import { Footer, Header, Snackbar, Auth } from "./components"
import { Home, Curicoo } from "./pages"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { StateProvider } from "./utils/state"
import { AUTH_MODES } from "./utils/constants"
import state from "./store"
import { CreateCuricooModal } from "./components/modals/CuricooModal"
// import theme from "./theme"

function App() {
  const [isModalOpen, setModalState] = useState(false)

  const closeModal = () => setModalState(false)
  const openModal = () => setModalState(true)

  return (
    <StateProvider
      initialState={state.initialState}
      reducer={state.mainReducer}
    >
      <Router>
        {/* <ThemeProvider theme={theme}> */}
        <CssBaseline />
        <Header onCreate={openModal}/>
        <main>
          <Route path="/" exact component={Home} />
          <Route path="/login" render={() => <Auth mode={AUTH_MODES.LOGIN} />} />
          <Route path="/signup" render={() => <Auth mode={AUTH_MODES.SIGNUP} />} />
          <Route
            path="/curicoo/:curicooSlugifiedKey"
            exact
            component={Curicoo}
          />
          <Snackbar />
        </main>
        <CreateCuricooModal open={isModalOpen} handleClose={closeModal} />
        <Footer />
        {/* </ThemeProvider> */}
      </Router>
    </StateProvider>
  )
}

export default App
