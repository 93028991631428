import {
  get,
  put,
  post,
  deleteHttp,
  createResponseFromRaw
} from "../utils/ajax"

export const getCuricooBySlugifiedKey = async slugifiedKey => {
  const response = await get(`/api/curicoo/bySlug/${slugifiedKey}`)

  return createResponseFromRaw(response)
}

export const createCuricoo = async curicoo => {
  const response = await post("/api/curicoo", { body: curicoo })

  return createResponseFromRaw(response)
}

export const updateCuricoo = async (curicooId, curicoo) => {
  const response = await put(`/api/curicoo/${curicooId}`, { body: curicoo })

  return createResponseFromRaw(response)
}

export const deleteCuricoo = async curicooId => {
  const response = await deleteHttp(`/api/curicoo/${curicooId}`)

  return createResponseFromRaw(response, { useText: true })
}

export const getTopCuricoos = async (pageSize, page = 1) => {
  if (!pageSize) throw new Error("You must provide a page size")

  const response = await get(
    `/api/curicoo/best?pageSize=${pageSize}&page=${page}`
  )

  return createResponseFromRaw(response)
}

export const searchCuricoos = async term => {
  const response = await get(`/api/curicoo/search/${term}`)

  return createResponseFromRaw(response)
}
