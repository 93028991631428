import React from "react"
import ConfirmationModal from "./ConfirmationModal"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { deleteLesson } from "../../../actions/lesson.action"

export default function DeleteLessonConfirmationModal({
  open,
  curicooId,
  lessonId,
  handleClose
}) {
  const [, dispatch] = useStateValue()

  const onDeleteLesson = async () => {
    const res = await deleteLesson(curicooId, lessonId)

    if (res.error) {
      toastMessage(
        dispatch,
        "error",
        `Unable to delete Lesson. Error: ${res.error}`
      )
    } else {
      toastMessage(dispatch, "success", "Lesson deleted successfully")
      dispatch({
        type: "curicoo.deleteLessonFromCurrent",
        lessonId,
        updatedLessons: res.body
      })
    }

    handleClose()
  }

  return (
    <ConfirmationModal
      open={open}
      title="Confirm deleting Lesson"
      message={`Are you sure you want to delete this Lesson?
      This action cannot be undone.`}
      onCancel={handleClose}
      onOk={onDeleteLesson}
      config={{ okButtonText: "Delete", okButtonColor: "secondary" }}
    />
  )
}
