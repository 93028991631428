import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import { DragDropContext } from "react-beautiful-dnd"
import CuricooLessons from "./components/CuricooLessons"
import ShareMenu from "../../components/ShareMenu"
import {
  CreateLessonModal,
  EditLessonModal
} from "../../components/modals/LessonModal"
import {
  DeleteCuricooConfirmationModal,
  DeleteLessonConfirmationModal
} from "../../components/modals/ConfirmationModal"
import { EditCuricooModal } from "../../components/modals/CuricooModal"
import useStyles from "./curicoo.style"

export default function Curicoo({ curicoo, isEditable, onLessonsReorder }) {
  const classes = useStyles()
  const [isCreateLessonModalOpen, setCreateLessonModalState] = useState(false)
  const [currentLesson, setCurrentLesson] = useState()
  const [currentLessonAction, setCurrentLessonAction] = useState()
  const [isEditCuricooModalOpen, setIsEditCuricooModalOpen] = useState(false)
  const [isDeleteCuricooModalOpen, setIsDeleteCuricooModalOpen] = useState(
    false
  )

  const modalsSetters = {
    CREATE_LESSON: setCreateLessonModalState,
    EDIT_CURICOO: setIsEditCuricooModalOpen,
    DELETE_CURICOO: setIsDeleteCuricooModalOpen
  }

  const setLesson = (lesson, action) => {
    if (lesson) {
      setCurrentLessonAction(action)
    }
    setCurrentLesson(lesson)
  }

  const setModal = (modalName, state) => () =>
    modalsSetters[modalName](state === "OPEN" ? true : false)

  const isEditLessonModalOpen =
    !!currentLesson && currentLessonAction === "EDIT"

  const isDeleteLessonConfirmationModalOpen =
    !!currentLesson && currentLessonAction === "DELETE"

  return (
    <Grid container className={classes.wrapper}>
      {curicoo ? (
        <>
          <Helmet>
            <title>{`Curicoo - ${curicoo.title}`}</title>
          </Helmet>
          <Grid item xs={10} className={classes.title}>
            <Typography variant="h3">{curicoo.title}</Typography>
          </Grid>
          <Grid item xs={10} className={classes.description}>
            <Typography variant="h5">{curicoo.description}</Typography>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={8}
            className={classes.actionButtonsWrapper}
          >
            <div className={classes.actionButtonsInternalWrapper}>
              {isEditable && (
                <>
                  <IconButton onClick={setModal("DELETE_CURICOO", "OPEN")}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={setModal("EDIT_CURICOO", "OPEN")}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
              <ShareMenu curicoo={curicoo} />
            </div>
          </Grid>
          <Grid
            container
            item
            xs={10}
            sm={10}
            md={8}
            className={classes.lessons}
          >
            {curicoo.lessons && (
              <DragDropContext onDragEnd={onLessonsReorder}>
                <CuricooLessons
                  lessons={curicoo.lessons}
                  isEditable={isEditable}
                  onEditLesson={(lesson) => setLesson(lesson, "EDIT")}
                  onDeleteLesson={(lesson) => setLesson(lesson, "DELETE")}
                />
              </DragDropContext>
            )}
          </Grid>
          {isEditable && (
            <Grid item xs={12} className={classes.description}>
              <Button
                color="primary"
                variant="contained"
                onClick={setModal("CREATE_LESSON", "OPEN")}
              >
                Create a new Lesson
              </Button>
            </Grid>
          )}
          <CreateLessonModal
            open={isCreateLessonModalOpen}
            handleClose={setModal("CREATE_LESSON", "CLOSE")}
            curicooId={curicoo.id}
          />
          {isEditCuricooModalOpen && ( //TODO: Handle memory leak warning
            <EditCuricooModal
              open={isEditCuricooModalOpen}
              handleClose={setModal("EDIT_CURICOO", "CLOSE")}
              curicooData={curicoo}
            />
          )}
          {isEditLessonModalOpen && (
            <EditLessonModal
              open={isEditLessonModalOpen}
              handleClose={() => setLesson()}
              curicooId={curicoo.id}
              lessonData={currentLesson}
            />
          )}
          <DeleteCuricooConfirmationModal
            curicooId={curicoo.id}
            open={isDeleteCuricooModalOpen}
            handleClose={setModal("DELETE_CURICOO", "CLOSE")}
          />
          {isDeleteLessonConfirmationModalOpen && (
            <DeleteLessonConfirmationModal
              open={isDeleteLessonConfirmationModalOpen}
              handleClose={() => setLesson()}
              lessonId={currentLesson.id}
              curicooId={curicoo.id}
            />
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  )
}
