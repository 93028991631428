import React from "react"
import LessonModal from "./LessonModal"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { createLesson } from "../../../actions/lesson.action"

export default function CreateLessonModalContainer({
  open,
  handleClose,
  curicooId
}) {
  const [, dispatch] = useStateValue()

  const onCreateLesson = async lesson => {
    const res = await createLesson(curicooId, lesson)

    if (res.body) {
      dispatch({
        type: "curicoo.addLessonToCurrent",
        curicooId,
        lesson: res.body
      })
      toastMessage(dispatch, "success", "Lesson created successfully")
      handleClose()
    } else {
      toastMessage(
        dispatch,
        "error",
        `Unable to create Lesson. ${res.error ? `Error: ${res.error}` : ""}`
      )
    }
  }

  return (
    <LessonModal
      open={open}
      mode={"CREATE"}
      handleClose={handleClose}
      handleFormSubmit={onCreateLesson}
    />
  )
}
