import React from "react"
import { useHistory, useLocation, Redirect } from "react-router-dom"
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import ModalOrMobile from "../ModalOrMobile"
import { AUTH_MODES } from "../../utils/constants"
import { useStateValue } from "../../utils/state"

export default function Auth({ mode }) {
  const history = useHistory()
  const { search } = useLocation()
  const [{ app: { user } }] = useStateValue()

  const query = new URLSearchParams(search)
  const returnPath = query.get("origin") || "/"

  if (user) return <Redirect to={returnPath} />

  const AuthAction = mode === AUTH_MODES.LOGIN ? SignIn : SignUp
  const returnToOrigin = () => history.push(returnPath)
  const props = { onSuccess: returnToOrigin, returnPath }

  return (
    <ModalOrMobile onClose={returnToOrigin}>
      <AuthAction {...props} />
    </ModalOrMobile>
  )
}
