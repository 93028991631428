import React from "react"
import Link from '@material-ui/core/Link'
import Typography from "@material-ui/core/Typography"
import useStyles from './footer.style'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://curicoo.com/">
        Curicoo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      {/* <Typography variant="h6" align="center" gutterBottom>
        Footer
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        Something here to give the footer a purpose!
      </Typography> */}
      <Copyright />
    </footer>
  )
}
