import React, { useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import IconButton from "@material-ui/core/IconButton"
import { getInitials } from "../../utils/user"
import useStyles from "./header.style"

export default function Header({
  onLogoClick,
  onLogin,
  onSignup,
  onLogout,
  onCreate,
  user
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const isLoggedIn = !!user

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    onLogout()
    handleMenuClose()
  }

  const renderLoggedInButtons = () => (
    <>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        onClick={onCreate}
      >
        Create
      </Button>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar>{getInitials(user)}</Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose} className={classes.menuButton}>
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} className={classes.menuButton}>
          Logout
        </MenuItem>
      </Menu>
    </>
  )

  const renderSignedOutButtons = () => (
    <>
      <Button
        variant="outlined"
        color="inherit"
        onClick={onLogin}
        className={classes.menuButton}
      >
        Login
      </Button>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={onSignup}
        className={classes.menuButton}
      >
        Sign Up
      </Button>
    </>
  )

  return (
    <AppBar position="static" color="transparent" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div onClick={onLogoClick} className={classes.title}>
          <Typography variant="h5" className={classes.logo}>
            Curicoo
          </Typography>
          <Typography variant="p" className={classes.slogan}>
            curated curriculums
          </Typography>
        </div>
        <div>
          {isLoggedIn ? renderLoggedInButtons() : renderSignedOutButtons()}
        </div>
      </Toolbar>
    </AppBar>
  )
}
