import React, { useEffect, useState } from "react"
import TopCuricoos from "./TopCuricoos"
import { getTopCuricoos } from "../../../../actions/curicoo.action"
import { useStateValue } from "../../../../utils/state"

const PAGE_SIZE = 9

const fetchTopCuricoos = async (page) => {
  const result = await getTopCuricoos(PAGE_SIZE, page)

  if (!result.error) {
    return result.body
  } else {
    throw new Error("Unable to fetch curicoos")
  }
}

export default function TopCuricoosContainer() {
  const [page, setPage] = useState(1)
  const [canFetchMore, setCanFetchMore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const [
    {
      curicoo: { topCuricoos, searchResults }
    },
    dispatch
  ] = useStateValue()

  useEffect(() => {
    const getCuricoos = async () => {
      if (isLoading && topCuricoos.length === 0) {
        const curicoos = await fetchTopCuricoos()
        dispatch({ type: "curicoo.setTopCuricoos", curicoos })
      }

      setIsLoading(false)
    }

    getCuricoos()
  })

  const fetchNextCuricoos = async () => {
    if (!canFetchMore) return

    const nextPage = page + 1
    const curicoos = await fetchTopCuricoos(nextPage)
    dispatch({ type: "curicoo.addTopCuricoos", curicoos })
    setPage(nextPage)

    if (curicoos.length < PAGE_SIZE) {
      setCanFetchMore(false)
    }
  }

  const curicoosToDisplay =
    searchResults.length > 0 ? searchResults : topCuricoos

  return <TopCuricoos curicoos={curicoosToDisplay} onNext={fetchNextCuricoos} />
}
