import React from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionActions from "@material-ui/core/AccordionActions"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import Typography from "@material-ui/core/Typography"
import useStyles from "./lessonCard.style"
import { lessonTypesMap } from "../../../../utils/lesson"
import { Draggable } from "react-beautiful-dnd"

export default function LessonCard({
  lesson,
  isEditable,
  index,
  onEditLesson,
  onDeleteLesson
}) {
  const classes = useStyles()
  const LessonTtypeIcon = lessonTypesMap[lesson.type].Icon

  const openLessonUrl = () => window.open(lesson.url)
  const copyLessonUrl = () => navigator.clipboard.writeText(lesson.url)

  return (
    <Draggable
      draggableId={`draggable-${lesson.id}`}
      isDragDisabled={!isEditable}
      key={lesson.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Accordion
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={snapshot.isDragging ? classes.draggedPanel : classes.panel}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={lesson.id}>
            <div className={classes.summary}>
              <Typography
                className={classes.lessonNumber}
              >{`Lesson ${lesson.order}`}</Typography>
              <div className={classes.lessonTitleWrapper}>
                <Typography className={classes.lessonTitle}>
                  {lesson.title}
                </Typography>
                <LessonTtypeIcon className={classes.lessonTypeIcon} />
              </div>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.lessonDescriptionWrapper}>
            <Typography>{lesson.description}</Typography>
            <div className={classes.lessonActions}>
              <Button
                variant="contained"
                startIcon={<OpenInNewIcon />}
                onClick={openLessonUrl}
              >
                Open URL in a new tab
              </Button>
              <Button variant="outlined" onClick={copyLessonUrl}>
                Copy URL to clipboard
              </Button>
            </div>
          </AccordionDetails>
          {isEditable && (
            <React.Fragment>
              <Divider />
              <AccordionActions>
                <Button size="small" color="secondary" onClick={onDeleteLesson}>
                  Delete
                </Button>
                <Button size="small" color="primary" onClick={onEditLesson}>
                  Edit
                </Button>
              </AccordionActions>
            </React.Fragment>
          )}
        </Accordion>
      )}
    </Draggable>
  )
}
