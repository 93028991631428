import React from "react"
import Snackbar from "./Snackbar"
import { useStateValue } from "../../utils/state"

export default function SnackbarContainer() {
  const [
    {
      app: {
        message: { type, message, visible, ...rest }
      }
    },
    dispatch
  ] = useStateValue()

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") return

    dispatch({ type: "app.closeMessage" })
  }

  return (
    <Snackbar
      handleClose={closeSnackbar}
      open={visible}
      message={message}
      variant={type}
      extraProps={rest}
    />
  )
}
