import React from "react"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import CuricooCard from "../../../../components/CuricooCard"
import useStyles from "./topCuricoos.style"

export default function TopCuricoos({ curicoos }) {
  const classes = useStyles()

  return (
    <Container className={classes.curicoosGrid} maxWidth="md">
      {curicoos && (
        <Grid container spacing={4}>
          {curicoos.map(curicoo => (
            <CuricooCard curicoo={curicoo} key={curicoo.id} />
          ))}
        </Grid>
      )}
    </Container>
  )
}
