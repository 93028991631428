import React from "react"
import useStyles from "./notFound.style"

export default function NotFound() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <h1 className={classes.code}>404</h1>
      <h1 className={classes.title}>
        The content you were looking for is not here :(
      </h1>
      <img
        src="/rooster-not-found.jpeg"
        alt="rooster searching"
        className={classes.image}
      />
    </div>
  )
}
