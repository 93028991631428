import { ReactComponent as TextIcon } from '../icons/text.svg';
import { ReactComponent as AudioIcon } from '../icons/audio.svg';
import { ReactComponent as VideoIcon } from '../icons/video.svg';


const lessonTypes = [
  { name: "Text", value: 1, Icon: TextIcon },
  { name: "Video", value: 2, Icon: VideoIcon },
  { name: "Audio", value: 3, Icon: AudioIcon }
]

const lessonTypesMap = lessonTypes.reduce((acc, LessonType) => {
  acc[LessonType.value] = LessonType
  return acc
}, {})

export {
  lessonTypes,
  lessonTypesMap
} 