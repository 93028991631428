import React from "react"
import Home from "./Home"
import { useStateValue } from "../../utils/state"

export default function HomeContainer() {
  const [
    {
      app: { user }
    }
  ] = useStateValue()

  return <Home isLoggedIn={!!user} />
}
