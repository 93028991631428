import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(4, 0, 4)
  },
  mainHero: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '40px'
  },
  heroTextBlock: {
    marginRight: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  heroImageBlock: {},
  heroImage: {
    maxWidth: '400px',
    height: 'auto'
  },
  curicooSearch: {
    marginTop: '15px'
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  }
}))
