import appStore from "./app.store"
import curicooStore from "./curicoo.store"

const mainReducer = ({ app, curicoo }, action) => {
  // TODO: Remove before production
  console.log(
    "%c%s",
    "background: green; font-size: 14px;",
    `*** Action was dispatched ***\n${action.type}\n`,
    JSON.stringify(action, null, 2)
  )

  const updatedState = {
    app: appStore.reducer(app, action),
    curicoo: curicooStore.reducer(curicoo, action)
  }

  console.log(
    "%c%s",
    "color: red; font-size: 14px;",
    `*** Updated state: ***\n`,
    JSON.stringify(updatedState.curicoo.current, null, 2)
  )

  return updatedState
}

const initialState = {
  app: appStore.initialState,
  curicoo: curicooStore.initialState
}

export default { mainReducer, initialState }
