import React, { useState } from "react"
import pick from "lodash/pick"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import { lessonTypes } from "../../../utils/lesson"
import useForm from "react-hook-form"

const LessonModalTexts = {
  CREATE: {
    title: "Create a new Lesson",
    formText:
      "In order to create a new lesson, please fill in the following details:",
    submitButton: "Create"
  },
  UPDATE: {
    title: "Edit Lesson",
    submitButton: "Save"
  }
}

export default function LessonModal({
  open,
  mode,
  lesson,
  handleClose,
  handleFormSubmit
}) {
  const [isLoading, setisLoading] = useState(false)
  const [selectedType, setSelectedType] = useState()
  const defaultValues = pick(lesson, ["url", "title", "description", "type"])
  const { register, handleSubmit, errors, triggerValidation } = useForm({
    defaultValues
  })

  const handleTypeChange = event => {
    setSelectedType(event.target.value)
    triggerValidation({ name: "type", value: event.target.value })
  }

  const onSubmit = async data => {
    data.type = +data.type

    setisLoading(true)
    await handleFormSubmit(data)
    setisLoading(false)
  }

  const texts = LessonModalTexts[mode]
  const selectedLessonType = selectedType || defaultValues.type || 6

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
        <DialogContent>
          {texts.formText && (
            <DialogContentText>{texts.formText}</DialogContentText>
          )}
          <TextField
            name="url"
            label="URL"
            type="url"
            fullWidth
            autoFocus
            margin="normal"
            variant="outlined"
            inputRef={register({ required: "URL is required" })}
            error={errors.url}
            helperText={errors.url ? errors.url.message : ""}
          />
          <TextField
            type="number"
            name="type"
            label="Type"
            select
            fullWidth
            margin="normal"
            variant="outlined"
            value={selectedLessonType}
            onChange={handleTypeChange}
            error={errors.type}
            inputRef={register}
            helperText={errors.type ? errors.type.message : ""}
          >
            {lessonTypes.map(lessonType => (
              <MenuItem key={lessonType.value} value={lessonType.value}>
                {lessonType.name}
              </MenuItem>
            ))}
          </TextField>
          <input
            type="hidden"
            name="type"
            defaultValue={selectedLessonType}
            ref={register({ required: "Type is required" })}
          />
          <TextField
            name="title"
            label="Title"
            type="text"
            fullWidth
            margin="normal"
            variant="outlined"
            inputRef={register({ required: "Title is required" })}
            error={errors.title}
            helperText={errors.title ? errors.title.message : ""}
          />
          <TextField
            name="description"
            label="Description"
            type="text"
            multiline
            rows="4"
            rowsMax="8"
            fullWidth
            margin="normal"
            variant="outlined"
            inputRef={register({ required: "Description is required" })}
            error={errors.description}
            helperText={errors.description ? errors.description.message : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            disabled={isLoading}
            onClick={handleClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} color="primary">
            {texts.submitButton}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
