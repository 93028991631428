import React from "react"
import ConfirmationModal from "./ConfirmationModal"
import { useHistory } from "react-router-dom"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { deleteCuricoo } from "../../../actions/curicoo.action"

export default function DeleteCuricooConfirmationModal({
  open,
  curicooId,
  handleClose
}) {
  const history = useHistory()
  const [, dispatch] = useStateValue()

  const onDeleteCuricoo = async () => {
    const res = await deleteCuricoo(curicooId)

    if (res.error) {
      toastMessage(
        dispatch,
        "error",
        `Unable to delete Curicoo. Error: ${res.error}`
      )
    } else {
      toastMessage(dispatch, "success", "Curicoo deleted successfully")
      history.push(`/`)
      dispatch({ type: "curicoo.deleteCuricoo" })
    }

    handleClose()
  }

  return (
    <ConfirmationModal
      open={open}
      title="Confirm deleting Curicoo"
      message={`Are you sure you want to delete this Curicoo?
      This action cannot be undone.`}
      onCancel={handleClose}
      onOk={onDeleteCuricoo}
      config={{ okButtonText: "Delete", okButtonColor: "secondary" }}
    />
  )
}
