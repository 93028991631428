import React from "react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { lessonTypesMap } from "../../utils/lesson"
import { ellipsis } from "../../utils/string"
import useStyles from "./curicooCard.style"

const MAX_DESCRIPTION_LENGTH = 160
const MAX_TITLE_LENGTH = 60
const imageUrl =
  "https://images.pexels.com/photos/159823/kids-girl-pencil-drawing-159823.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"

export default function CuricooCard({ curicoo, onView }) {
  const classes = useStyles()
  const updatedAt = new Date(curicoo.updatedAt).toLocaleString().split(",")[0]
  const description = ellipsis(curicoo.description, MAX_DESCRIPTION_LENGTH)
  const title = ellipsis(curicoo.title, MAX_TITLE_LENGTH)

  return (
    <Grid item key={curicoo.id} xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardMedia className={classes.cardMedia} image={imageUrl}>
          <div className={classes.cardTitleWrapper}>
            <Typography
              className={classes.title}
              variant="h5"
              component="h1"
            >
              {title}
            </Typography>
            <div className={classes.metadata}>
              <div>{curicoo["owner.name"]}</div>
              <div>|</div>
              <div>{updatedAt}</div>
            </div>
          </div>
          <div className={classes.cardTitleOverlay} />
        </CardMedia>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardDescription}>
            <Typography>{description}</Typography>
          </div>
          <Divider className={classes.cardContentDivider} variant="middle" />
          <div className={classes.cardDetails}>
            <Typography gutterBottom>
              {`${curicoo.lessonsCount} ${
                curicoo.lessonsCount === 1 ? "lesson" : "lessons"
              }`}
            </Typography>
            <div className={classes.cardLessonTypes}>
              {curicoo.lessonTypes.map((type) => {
                const LessonTypeIcon = lessonTypesMap[type].Icon
                return <LessonTypeIcon />
              })}
            </div>
          </div>
        </CardContent>
        <CardActions>
          <Button
            className={classes.cardGotoButton}
            variant="contained"
            disableElevation
            onClick={onView}
          >
            View Curicoo
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}
