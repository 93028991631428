import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
  container: {
    textAlign: "center"
  },
  title: {
    marginTop: 0
  },
  code: {
    fontSize: "3em",
    marginBottom: 0
  },
  image: {
    width: "90%",
    maxWidth: "690px",
    height: "auto"
  }
}))
