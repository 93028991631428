import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => ({
  wrapper: {
    justifyContent: "center"
  },
  title: {
    textAlign: "center",
    paddingTop: "30px",
    wordWrap: 'break-word'
  },
  description: {
    textAlign: "center",
    padding: "10px 20px",
    wordWrap: 'break-word'
  },
  lessons: {
    justifyContent: "center",
    paddingTop: "30px",
    width: "100%"
  },
  actionButtonsWrapper: {
    justifyContent: "center",
    paddingTop: "15px",
    display: "flex"
  },
  actionButtonsInternalWrapper: {
    width: "550px",
    maxWidth: "550px",
    display: "flex",
    alignItems: "left"
  },
  loader: {
    margin: "50px"
  }
}))
