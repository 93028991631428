import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  card: {
    maxWidth: '300px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    height: '130px',
    position: 'relative'
  },
  cardTitleWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    wordBreak: 'break-word',
    textAlign: 'center',
    color: 'white',
    zIndex: 10,
    width: '100%',
    height: '100%',
    padding: '10px'
  },
  cardTitleOverlay: {
    position: 'relative',
    backgroundColor: 'black',
    opacity: '50%',
    height: '100%'
  },
  title: {
    fontSize: '22px'
  },
  metadata: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '5px 15px'
  },
  cardContent: {
    flexGrow: 1,
    wordWrap: 'break-word'
  },
  cardContentDivider: {
    margin: '15px 0'
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardDescription: {
    height: '130px'
  },
  cardLessonTypes: {
    marginTop: '10px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around'
  },
  cardGotoButton: {
    width: '100%',
    margin: '5px 15px',
    backgroundColor: '#383838',
    color: '#ffffff'
  }
}));