import React, { useEffect } from "react"
import Header from "./Header"
import { useHistory, useLocation } from "react-router-dom"
import { useStateValue } from "../../utils/state"
import { toastMessage } from "../../utils/toast"
import { logout } from "../../actions/auth.action"
import { getActiveUser } from "../../actions/user.action"
import isEmpty from "lodash/isEmpty"

export default function HeaderContainer({ onCreate }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const [
    {
      app: { user }
    },
    dispatch
  ] = useStateValue()

  useEffect(() => {
    // Client side implementation of the "remember me" functionality
    if (user) return

    const fetchUser = async () => {
      const res = await getActiveUser()

      if (!isEmpty(res.body)) {
        dispatch({ type: "app.setUser", user: res.body })
      }
    }

    fetchUser()
  }, [dispatch, user])

  const onLogoClick = () => history.push("/")
  const onLogin = () => history.push(`/login?origin=${pathname}`)
  const onSignup = () => history.push(`/signup?origin=${pathname}`)
  const onLogout = async () => {
    const res = await logout()

    if (res.error) {
      toastMessage(dispatch, "error", "Failed to logout")
    } else {
      dispatch({
        type: "app.setUser",
        user: undefined
      })
    }
  }

  return (
    <Header
      user={user}
      onCreate={onCreate}
      onLogin={onLogin}
      onLogoClick={onLogoClick}
      onLogout={onLogout}
      onSignup={onSignup}
    />
  )
}
