const initialState = {
  message: { visible: false, type: "error", message: "" }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "app.showMessage": {
      return {
        ...state,
        message: { visible: true, ...action.details }
      }
    }
    case "app.closeMessage": {
      return {
        ...state,
        message: Object.assign(state.message, { visible: false })
      }
    }
    case "app.setUser": {
      return {
        ...state,
        user: action.user
      }
    }
    default:
      return state
  }
}

export default { initialState, reducer }
