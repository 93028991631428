import React from "react"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import useForm from "react-hook-form"
import useStyles from "./curicooSearch.style"

export default function CustomizedInputBase({ onSearch, onClear }) {
  const classes = useStyles()
  const { register, handleSubmit, watch, reset } = useForm()
  const searchTerm = watch("searchTerm")

  const onSubmit = async data => onSearch(data.searchTerm)
  const onReset = () => {
    reset()
    onClear()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.root}>
        <InputBase
          name="searchTerm"
          className={classes.input}
          placeholder={"Search for curriculums"}
          inputProps={{ "aria-label": "search" }}
          inputRef={register({ required: true })}
        />
        {searchTerm && (
          <IconButton
            className={classes.iconButton}
            aria-label="clear search"
            onClick={onReset}
          >
            <ClearIcon />
          </IconButton>
        )}
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          type="submit"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </form>
  )
}
