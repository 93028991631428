import React from "react"
import { Helmet } from "react-helmet"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TopCuricoos from "./components/TopCuricoos"
import CuricooSearch from "./components/CuricooSearch"
import useStyles from "./home.style"

export default function Home({ isLoggedIn }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Helmet>
        <title>Curicoo</title>
      </Helmet>
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth="xl">
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            className={classes.heroButtons}
          >
            <div className={classes.mainHero}>
              <div className={classes.heroTextBlock}>
                <Typography variant="h1">Curicoo</Typography>
                <Typography variant="h4">
                  The internet has all the knowledge you need.
                  <br />
                  Now you can access it. Easily.
                </Typography>
                <div className={classes.curicooSearch}>
                  <CuricooSearch />
                </div>
              </div>
              <Box
                className={classes.heroImageBlock}
                display={{ xs: "none", sm: "block" }}
              >
                <img
                  src="mainImage.svg"
                  alt="A women learning online"
                  className={classes.heroImage}
                />
              </Box>
            </div>
            {/* <Typography>Buttom buttons</Typography> */}
          </Grid>
        </Container>
      </div>
      {/* End hero unit */}
      <TopCuricoos />
    </React.Fragment>
  )
}
