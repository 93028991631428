import React, { useState } from "react"
import pick from "lodash/pick"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import useForm from "react-hook-form"

const curicooModalTexts = {
  CREATE: {
    title: "Create a new Curicoo",
    formText:
      "In order to create a new Curicoo, please fill in the following details:",
    submitButton: "Create"
  },
  UPDATE: {
    title: "Edit Curicoo",
    submitButton: "Save"
  }
}

export default function CuricooModal({
  open,
  mode,
  curicoo,
  handleClose,
  onFormSubmit
}) {
  const defaultValues = pick(curicoo, ["title", "description"])
  const { register, handleSubmit, errors } = useForm({ defaultValues })
  const [isLoading, setisLoading] = useState(false)

  const onSubmit = async data => {
    setisLoading(true)
    await onFormSubmit(data)
    setisLoading(false)
  }

  const texts = curicooModalTexts[mode]

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="form-dialog-title">{texts.title}</DialogTitle>
        <DialogContent>
          {texts.formText && (
            <DialogContentText>{texts.formText}</DialogContentText>
          )}
          <TextField
            name="title"
            label="Title"
            type="text"
            fullWidth
            autoFocus
            error={errors.title}
            margin="normal"
            variant="outlined"
            inputRef={register({ required: "Title is required" })}
            helperText={errors.title ? errors.title.message : ""}
          />
          <TextField
            name="description"
            label="Description"
            type="text"
            multiline
            rows="4"
            rowsMax="6"
            fullWidth
            margin="normal"
            variant="outlined"
            error={errors.description}
            inputRef={register({
              required: "Description is required",
              minLength: { value: 10, message: "Description is too short" }
            })}
            helperText={errors.description ? errors.description.message : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="reset"
            disabled={isLoading}
            onClick={handleClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} color="primary">
            {texts.submitButton}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
