import React from "react"
import LessonCard from "./LessonCard"

export default function LessonCardContainer({
  lesson,
  isEditable,
  index,
  onEditLesson,
  onDeleteLesson
}) {
  return (
    <LessonCard
      lesson={lesson}
      isEditable={isEditable}
      index={index}
      onEditLesson={() => onEditLesson(lesson)}
      onDeleteLesson={() => onDeleteLesson(lesson)}
    />
  )
}
