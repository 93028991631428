export const toastMessage = (dispatch, type, message, options = {}) =>
  dispatch({
    type: "app.showMessage",
    details: {
      autoHideDuration: 5000,
      ...options,
      type,
      message
    }
  })
