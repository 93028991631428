import React from "react"
import CuricooModal from "./CuricooModal"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { updateCuricoo } from "../../../actions/curicoo.action"

export default function EditCuricooModalContainer({ open, handleClose, curicooData }) {
  const [, dispatch] = useStateValue()

  const onUpdateCuricoo = async curicoo => {
    const res = await updateCuricoo(curicooData.id, curicoo)

    if (res.error) {
      toastMessage(dispatch, "error", "Unable to create Curicoo")
    } else {
      dispatch({
        type: "curicoo.updateCuricooDetails",
        curicoo: res.body
      })
      handleClose()
    }
  }

  return (
    <CuricooModal
      open={open}
      mode={"UPDATE"}
      curicoo={curicooData}
      handleClose={handleClose}
      onFormSubmit={onUpdateCuricoo}
    />
  )
}
