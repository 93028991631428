import React, { useState } from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share"
import IconButton from "@material-ui/core/IconButton"
import ShareIcon from "@material-ui/icons/Share"
import isMobile from "is-mobile"
import useStyles from "./shareMenu.style"

const buttons = [
  {
    button: EmailShareButton,
    icon: EmailIcon
  },
  {
    button: WhatsappShareButton,
    icon: WhatsappIcon
  },
  {
    button: TelegramShareButton,
    icon: TelegramIcon
  },
  {
    button: TwitterShareButton,
    icon: TwitterIcon
  },
  {
    button: FacebookShareButton,
    icon: FacebookIcon,
    buttonOptions: { hashtag: "#curicoo" }
  },
  {
    button: LinkedinShareButton,
    icon: LinkedinIcon,
    buttonOptions: { source: "Curicoo" }
  },
  {
    button: RedditShareButton,
    icon: RedditIcon
  }
]

export default function ShareMenu({ pageUrl, curicoo: { title } }) {
  const classes = useStyles()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const basicProps = { url: pageUrl, title }
  const basicIconProps = { size: 32, round: true }

  const onHover = () => {
    if (!isMenuOpen) {
      setIsMenuOpen(true)
    }
  }

  const onClick = () => {
    if (isMobile()) {
      setIsMenuOpen(!isMenuOpen)
    }
  }

  const onHoverEnd = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
  }

  return (
    <div className={classes.shareMenuContainer} onMouseLeave={onHoverEnd}>
      <IconButton
        className={classes.rootShareButton}
        onMouseEnter={onHover}
        onClick={onClick}
      >
        <ShareIcon />
      </IconButton>
      {isMenuOpen && (
        <div>
          {buttons.map((Share) => {
            const btnProps = Object.assign({}, basicProps, Share.buttonOptions)
            const iconProps = Object.assign(
              {},
              basicIconProps,
              Share.iconOptions
            )

            return (
              <Share.button {...btnProps} className={classes.shareButton}>
                <Share.icon {...iconProps} />
              </Share.button>
            )
          })}
        </div>
      )}
    </div>
  )
}
