import React from "react"
import CuricooCard from "./CuricooCard"
import { useHistory } from "react-router-dom"
import { useStateValue } from "../../utils/state"
import { goToCuricoo } from "../../utils/curicoo"

export default function CuricooCardContainer({ curicoo }) {
  const history = useHistory()
  const [, dispatch] = useStateValue()

  const onView = () => goToCuricoo(history, dispatch, curicoo.slugifiedKey)

  return <CuricooCard curicoo={curicoo} onView={onView} />
}
