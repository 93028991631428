import { get, post, createResponseFromRaw } from "../utils/ajax"
import { getActiveUser } from "./user.action"

export const login = async loginDetails => {
  const response = await post("/auth/login", { body: loginDetails })
  const loginResult = await createResponseFromRaw(response)

  return loginResult.error ? loginResult : getActiveUser()
}

export const logout = async () => {
  const response = await get("/auth/logout")

  return createResponseFromRaw(response)
}
