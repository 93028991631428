import React from "react"
import SignUp from "./SignUp"
import { useStateValue } from "../../../utils/state"
import { toastMessage } from "../../../utils/toast"
import { signUp } from "../../../actions/user.action"

export default function SignUpContainer({ onSuccess, returnPath }) {
  const [, dispatch] = useStateValue()

  const onSignUp = async userDetails => {
    const res = await signUp(userDetails)

    if (res.error) {
      toastMessage(dispatch, "error", "Unable to create user")
    } else {
      dispatch({
        type: "app.setUser",
        user: res.body
      })

      onSuccess()
    }
  }

  return <SignUp onSignUp={onSignUp} returnPath={returnPath} />
}
